
require('./bootstrap');
require('./vendor/vcs');
require('./dedicated');

import {
    Lightbox,
    Carousel,
    Modal,
    initTE,
} from "tw-elements";

initTE({ Lightbox, Carousel, Modal });

// function windowScroll() {
//     if (
//         document.body.scrollTop >= sticky ||
//         document.documentElement.scrollTop >= sticky
//     ) {
//         navbar.classList.add("is-sticky");
//         sticky_register_button.classList.add("is-sticky");
//     } else {
//         navbar.classList.remove("is-sticky");
//         sticky_register_button.classList.remove("is-sticky");
//     }
// }
// const navbar = document.getElementById("navbar");
// const sticky_register_button = document.getElementById('sticky-register-button');
// var sticky = navbar.offsetTop;

function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
    ) {
        navbar.classList.add("is-sticky");
    } else {
        navbar.classList.remove("is-sticky");
    }
}

window.addEventListener('scroll', (ev) => {
    ev.preventDefault();
    windowScroll();
})

// Navbar Active Class

var spy = new Gumshoe('#navbar-navlist a', {
    // Active classes
    navClass: 'active', // applied to the nav list item
    contentClass: 'active', // applied to the content
    offset: 70
});


// Smooth scroll
var scroll = new SmoothScroll('#navbar-navlist a', {
    speed: 500,
    offset: 70
});




// Menu Collapse

const toggleCollapse = (elementId, show = true) => {
    const collapseEl = document.getElementById(elementId);
    if (show) {
        collapseEl.classList.remove('hidden');
    } else {
        collapseEl.classList.add('hidden');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    // Toggle target elements using [data-collapse]
    document.querySelectorAll('[data-collapse]').forEach(function (collapseToggleEl) {
        var collapseId = collapseToggleEl.getAttribute('data-collapse');

        collapseToggleEl.addEventListener('click', function () {
            toggleCollapse(collapseId, document.getElementById(collapseId).classList.contains('hidden'));
        });
    });
});

window.toggleCollapse = toggleCollapse;


//
//Dropdown
//

document.addEventListener('DOMContentLoaded', () => {
    // Toggle dropdown elements using [data-dropdown-toggle]
    document.querySelectorAll('[data-dropdown-toggle]').forEach(function (dropdownToggleEl) {
        const dropdownMenuId = dropdownToggleEl.getAttribute('data-dropdown-toggle');
        const dropdownMenuEl = document.getElementById(dropdownMenuId);

        // options
        const placement = dropdownToggleEl.getAttribute('data-dropdown-placement');

        dropdownToggleEl.addEventListener('click', function (event) {
            var element = event.target;

            // toggle when click on the button
            dropdownMenuEl.classList.toggle('show');

            function handleDropdownOutsideClick(event) {
                var targetElement = event.target; // clicked element
                if (targetElement !== dropdownMenuEl && targetElement !== dropdownToggleEl && !dropdownToggleEl.contains(targetElement)) {
                    dropdownMenuEl.classList.remove('show');
                    document.body.removeEventListener('click', handleDropdownOutsideClick, true);
                }
            }

            // hide popper when clicking outside the element
            document.body.addEventListener('click', handleDropdownOutsideClick, true);
        });
    });
});



//
// Contact Form Validation
//

function validateForm() {
    var name = document.forms["myForm"]["name"].value;
    var email = document.forms["myForm"]["email"].value;
    var subject = document.forms["myForm"]["subject"].value;
    var comments = document.forms["myForm"]["comments"].value;
    document.getElementById("error-msg").style.opacity = 0;
    document.getElementById('error-msg').innerHTML = "";
    if (name == "" || name == null) {
        document.getElementById('error-msg').innerHTML = "<div class='p-3 text-center mb-4 text-sm text-orange-700 bg-orange-100 rounded error_message'>Please enter a Name</div>";
        fadeIn();
        return false;
    }
    if (email == "" || email == null) {
        document.getElementById('error-msg').innerHTML = "<div class='p-3 text-center mb-4 text-sm text-orange-700 bg-orange-100 rounded error_message'>Please enter a Email</div>";
        fadeIn();
        return false;
    }
    if (subject == "" || subject == null) {
        document.getElementById('error-msg').innerHTML = "<div class='p-3 text-center mb-4 text-sm text-orange-700 bg-orange-100 rounded error_message'>Please enter a Subject</div>";
        fadeIn();
        return false;
    }
    if (comments == "" || comments == null) {
        document.getElementById('error-msg').innerHTML = "<div class='p-3 text-center mb-4 text-sm text-orange-700 bg-orange-100 rounded error_message'>Please enter a Comments</div>";
        fadeIn();
        return false;
    }

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            document.getElementById("simple-msg").innerHTML = this.responseText;
            document.forms["myForm"]["name"].value = "";
            document.forms["myForm"]["email"].value = "";
            document.forms["myForm"]["subject"].value = "";
            document.forms["myForm"]["comments"].value = "";
        }
    };
    xhttp.open("POST", "assets/php/contact.php", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.send("name=" + name + "&email=" + email + "&subject=" + subject + "&comments=" + comments);
    return false;
}

function fadeIn() {
    var fade = document.getElementById("error-msg");
    var opacity = 0;
    var intervalID = setInterval(function () {
        if (opacity < 1) {
            opacity = opacity + 0.5
            fade.style.opacity = opacity;
        } else {
            clearInterval(intervalID);
        }
    }, 200);
}

//

function initTabs(containerId,contentsId,ancestor_selector=false)
{
    let tabsContainer = document.querySelector("#"+containerId);
    if(!tabsContainer){
        return;
    }

    let tabTogglers = tabsContainer.querySelectorAll("#"+containerId+" a");


    tabTogglers.forEach(function(toggler) {
        toggler.addEventListener("click", function(e) {
            e.preventDefault();
            let tabName = this.getAttribute("href");

            let tabContents = document.querySelector("#"+contentsId);

            for (let i = 0; i < tabContents.children.length; i++) {
                // tabTogglers[i].parentElement.classList.remove("border-t", "border-r", "border-l", "-mb-px", "bg-white");
                tabTogglers[i].parentElement.classList.remove("active");
                tabTogglers[i].classList.remove("active");
                tabContents.children[i].classList.remove("hidden");
                if ("#" + tabContents.children[i].id === tabName) {
                    continue;
                }
                tabContents.children[i].classList.add("hidden");

            }
            // e.target.parentElement.classList.add("border-t", "border-r", "border-l", "-mb-px", "bg-white");
            e.target.parentElement.classList.add('active');
            if(ancestor_selector){
                let ancestorNodes = document.getElementsByClassName(ancestor_selector);
                for (let i = 0; i < ancestorNodes.length; i++) {
                    ancestorNodes[i].classList.remove("active");
                }
                let ancestor = e.target.closest('.'+ancestor_selector);
                if(ancestor){
                    ancestor.classList.add("active");
                }
            }
        });
    });
}
document.addEventListener('DOMContentLoaded', () => {
    initTabs('tabs','tab-contents');
    initTabs('schedule_tabs','schedule-tab-contents');
    initTabs('schedule_tabs2','schedule2-tab-contents');
    initTabs('speaker_tabs','speaker-tab-contents','top-tab-div');
});


